import { CssBaseline } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Admin,
  DataProvider,
  Loading,
  Resource,
  usePermissions,
} from "react-admin";
import { buildDataProvider } from "./core/data-provider";
import { i18nProvider } from "./core/i18n";
import { authProvider } from "./core/auth-provider";
import { theme } from "./core/theme";
import { UsersList } from "./modules/users/users-list";
import { CoursePurchasesCreate } from "./modules/course-purchases/course-purchases-create";
import { CoursePurchasesEdit } from "./modules/course-purchases/course-purchases-edit";
import { CoursePurchasesList } from "./modules/course-purchases/course-purchases-list";
import { CoursesCreate } from "./modules/courses/courses-create";
import { CoursesEdit } from "./modules/courses/courses-edit";
import { CoursesList } from "./modules/courses/courses-list";
import { LessonsCreate } from "./modules/lessons/lessons-create";
import { LessonsEdit } from "./modules/lessons/lessons-edit";
import { LessonsList } from "./modules/lessons/lessons-list";
import { UsersCreate } from "./modules/users/users-create";
import { UsersEdit } from "./modules/users/users-edit";

function App() {
  const [dataProvider, setDataProvider] =
    useState<DataProvider<string> | null>(null);

  useEffect(() => {
    const getDataProvider = async () => {
      const dp = await buildDataProvider();

      setDataProvider(dp);
    };

    getDataProvider();
  }, []);

  if (!dataProvider) {
    return <Loading />;
  }

  return (
    <>
      <CssBaseline />
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        requireAuth
        theme={theme}
      >
        {(permissions) => {
          return [
            permissions?.find((item: any) => item?.resource === "users") ? (
              <Resource
                name="users"
                list={UsersList}
                edit={UsersEdit}
                create={UsersCreate}
                options={{ label: "Користувач" }}
              ></Resource>
            ) : null,

            permissions?.find((item: any) => item?.resource === "courses") ? (
              <Resource
                name="courses"
                list={CoursesList}
                edit={CoursesEdit}
                create={CoursesCreate}
                options={{ label: "Курси" }}
              ></Resource>
            ) : null,

            permissions?.find((item: any) => item?.resource === "lessons") ? (
              <Resource
                name="lessons"
                list={LessonsList}
                edit={LessonsEdit}
                create={LessonsCreate}
                options={{ label: "Уроки" }}
              ></Resource>
            ) : null,

            permissions?.find(
              (item: any) => item?.resource === "course_purchases"
            ) ? (
              <Resource
                name="course_purchases"
                list={CoursePurchasesList}
                edit={CoursePurchasesEdit}
                create={CoursePurchasesCreate}
                options={{ label: "Підписки на курси" }}
              ></Resource>
            ) : null,
          ];
        }}
      </Admin>
    </>
  );
}

export default App;
