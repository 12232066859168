import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CloudinarySignatureOutput = {
  __typename?: 'CloudinarySignatureOutput';
  apiKey: Scalars['String'];
  cloudName: Scalars['String'];
  publicId: Scalars['String'];
  signature: Scalars['String'];
  timestamp: Scalars['Int'];
};

export type GetUserOutput = {
  __typename?: 'GetUserOutput';
  email: Scalars['String'];
  id: Scalars['String'];
  role: Scalars['String'];
  username: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  accessToken: Scalars['String'];
};

export type RegisterUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  role: Scalars['String'];
  username: Scalars['String'];
};

export type RegisterUserOutput = {
  __typename?: 'RegisterUserOutput';
  email: Scalars['String'];
  id: Scalars['uuid'];
  password: Scalars['String'];
  role: Scalars['String'];
  username: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  email: Scalars['String'];
  id: Scalars['uuid'];
  is_deleted: Scalars['Boolean'];
  password: Scalars['String'];
  role: Scalars['String'];
  username: Scalars['String'];
};

export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput';
  email: Scalars['String'];
  id: Scalars['uuid'];
  is_deleted: Scalars['Boolean'];
  password: Scalars['String'];
  role: Scalars['String'];
  username: Scalars['String'];
};

/** columns and relationships of "course_purchases" */
export type Course_Purchases = {
  __typename?: 'course_purchases';
  /** An object relationship */
  course: Courses;
  course_id: Scalars['uuid'];
  id: Scalars['String'];
  payment_id?: Maybe<Scalars['Int']>;
  payment_status: Payment_Status_Enum;
  /** An object relationship */
  payment_status_enum: Payment_Status;
  purchase_date: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "course_purchases" */
export type Course_Purchases_Aggregate = {
  __typename?: 'course_purchases_aggregate';
  aggregate?: Maybe<Course_Purchases_Aggregate_Fields>;
  nodes: Array<Course_Purchases>;
};

export type Course_Purchases_Aggregate_Bool_Exp = {
  count?: InputMaybe<Course_Purchases_Aggregate_Bool_Exp_Count>;
};

export type Course_Purchases_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Course_Purchases_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Course_Purchases_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "course_purchases" */
export type Course_Purchases_Aggregate_Fields = {
  __typename?: 'course_purchases_aggregate_fields';
  avg?: Maybe<Course_Purchases_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Course_Purchases_Max_Fields>;
  min?: Maybe<Course_Purchases_Min_Fields>;
  stddev?: Maybe<Course_Purchases_Stddev_Fields>;
  stddev_pop?: Maybe<Course_Purchases_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Course_Purchases_Stddev_Samp_Fields>;
  sum?: Maybe<Course_Purchases_Sum_Fields>;
  var_pop?: Maybe<Course_Purchases_Var_Pop_Fields>;
  var_samp?: Maybe<Course_Purchases_Var_Samp_Fields>;
  variance?: Maybe<Course_Purchases_Variance_Fields>;
};


/** aggregate fields of "course_purchases" */
export type Course_Purchases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Course_Purchases_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_purchases" */
export type Course_Purchases_Aggregate_Order_By = {
  avg?: InputMaybe<Course_Purchases_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Course_Purchases_Max_Order_By>;
  min?: InputMaybe<Course_Purchases_Min_Order_By>;
  stddev?: InputMaybe<Course_Purchases_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Course_Purchases_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Course_Purchases_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Course_Purchases_Sum_Order_By>;
  var_pop?: InputMaybe<Course_Purchases_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Course_Purchases_Var_Samp_Order_By>;
  variance?: InputMaybe<Course_Purchases_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_purchases" */
export type Course_Purchases_Arr_Rel_Insert_Input = {
  data: Array<Course_Purchases_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Course_Purchases_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Purchases_Avg_Fields = {
  __typename?: 'course_purchases_avg_fields';
  payment_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_purchases" */
export type Course_Purchases_Avg_Order_By = {
  payment_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_purchases". All fields are combined with a logical 'AND'. */
export type Course_Purchases_Bool_Exp = {
  _and?: InputMaybe<Array<Course_Purchases_Bool_Exp>>;
  _not?: InputMaybe<Course_Purchases_Bool_Exp>;
  _or?: InputMaybe<Array<Course_Purchases_Bool_Exp>>;
  course?: InputMaybe<Courses_Bool_Exp>;
  course_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  payment_id?: InputMaybe<Int_Comparison_Exp>;
  payment_status?: InputMaybe<Payment_Status_Enum_Comparison_Exp>;
  payment_status_enum?: InputMaybe<Payment_Status_Bool_Exp>;
  purchase_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_purchases" */
export enum Course_Purchases_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoursePurchasesPkey = 'course_purchases_pkey'
}

/** input type for incrementing numeric columns in table "course_purchases" */
export type Course_Purchases_Inc_Input = {
  payment_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "course_purchases" */
export type Course_Purchases_Insert_Input = {
  course?: InputMaybe<Courses_Obj_Rel_Insert_Input>;
  course_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  payment_id?: InputMaybe<Scalars['Int']>;
  payment_status?: InputMaybe<Payment_Status_Enum>;
  payment_status_enum?: InputMaybe<Payment_Status_Obj_Rel_Insert_Input>;
  purchase_date?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Course_Purchases_Max_Fields = {
  __typename?: 'course_purchases_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['String']>;
  payment_id?: Maybe<Scalars['Int']>;
  purchase_date?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "course_purchases" */
export type Course_Purchases_Max_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_id?: InputMaybe<Order_By>;
  purchase_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Purchases_Min_Fields = {
  __typename?: 'course_purchases_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['String']>;
  payment_id?: Maybe<Scalars['Int']>;
  purchase_date?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "course_purchases" */
export type Course_Purchases_Min_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_id?: InputMaybe<Order_By>;
  purchase_date?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "course_purchases" */
export type Course_Purchases_Mutation_Response = {
  __typename?: 'course_purchases_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Course_Purchases>;
};

/** on_conflict condition type for table "course_purchases" */
export type Course_Purchases_On_Conflict = {
  constraint: Course_Purchases_Constraint;
  update_columns?: Array<Course_Purchases_Update_Column>;
  where?: InputMaybe<Course_Purchases_Bool_Exp>;
};

/** Ordering options when selecting data from "course_purchases". */
export type Course_Purchases_Order_By = {
  course?: InputMaybe<Courses_Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payment_id?: InputMaybe<Order_By>;
  payment_status?: InputMaybe<Order_By>;
  payment_status_enum?: InputMaybe<Payment_Status_Order_By>;
  purchase_date?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: course_purchases */
export type Course_Purchases_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "course_purchases" */
export enum Course_Purchases_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  PurchaseDate = 'purchase_date',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "course_purchases" */
export type Course_Purchases_Set_Input = {
  course_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  payment_id?: InputMaybe<Scalars['Int']>;
  payment_status?: InputMaybe<Payment_Status_Enum>;
  purchase_date?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Course_Purchases_Stddev_Fields = {
  __typename?: 'course_purchases_stddev_fields';
  payment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_purchases" */
export type Course_Purchases_Stddev_Order_By = {
  payment_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Purchases_Stddev_Pop_Fields = {
  __typename?: 'course_purchases_stddev_pop_fields';
  payment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_purchases" */
export type Course_Purchases_Stddev_Pop_Order_By = {
  payment_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Purchases_Stddev_Samp_Fields = {
  __typename?: 'course_purchases_stddev_samp_fields';
  payment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_purchases" */
export type Course_Purchases_Stddev_Samp_Order_By = {
  payment_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "course_purchases" */
export type Course_Purchases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Course_Purchases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Course_Purchases_Stream_Cursor_Value_Input = {
  course_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['String']>;
  payment_id?: InputMaybe<Scalars['Int']>;
  payment_status?: InputMaybe<Payment_Status_Enum>;
  purchase_date?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Course_Purchases_Sum_Fields = {
  __typename?: 'course_purchases_sum_fields';
  payment_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course_purchases" */
export type Course_Purchases_Sum_Order_By = {
  payment_id?: InputMaybe<Order_By>;
};

/** update columns of table "course_purchases" */
export enum Course_Purchases_Update_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  PaymentStatus = 'payment_status',
  /** column name */
  PurchaseDate = 'purchase_date',
  /** column name */
  UserId = 'user_id'
}

export type Course_Purchases_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Course_Purchases_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Course_Purchases_Set_Input>;
  /** filter the rows which have to be updated */
  where: Course_Purchases_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Course_Purchases_Var_Pop_Fields = {
  __typename?: 'course_purchases_var_pop_fields';
  payment_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_purchases" */
export type Course_Purchases_Var_Pop_Order_By = {
  payment_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Purchases_Var_Samp_Fields = {
  __typename?: 'course_purchases_var_samp_fields';
  payment_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_purchases" */
export type Course_Purchases_Var_Samp_Order_By = {
  payment_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Purchases_Variance_Fields = {
  __typename?: 'course_purchases_variance_fields';
  payment_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_purchases" */
export type Course_Purchases_Variance_Order_By = {
  payment_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "courses" */
export type Courses = {
  __typename?: 'courses';
  /** An array relationship */
  course_purchases: Array<Course_Purchases>;
  /** An aggregate relationship */
  course_purchases_aggregate: Course_Purchases_Aggregate;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  is_deleted: Scalars['Boolean'];
  /** An array relationship */
  lessons: Array<Lessons>;
  /** An aggregate relationship */
  lessons_aggregate: Lessons_Aggregate;
  order: Scalars['Int'];
  preview: Scalars['String'];
  price: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "courses" */
export type CoursesCourse_PurchasesArgs = {
  distinct_on?: InputMaybe<Array<Course_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Purchases_Order_By>>;
  where?: InputMaybe<Course_Purchases_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesCourse_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Purchases_Order_By>>;
  where?: InputMaybe<Course_Purchases_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesLessonsArgs = {
  distinct_on?: InputMaybe<Array<Lessons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lessons_Order_By>>;
  where?: InputMaybe<Lessons_Bool_Exp>;
};


/** columns and relationships of "courses" */
export type CoursesLessons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lessons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lessons_Order_By>>;
  where?: InputMaybe<Lessons_Bool_Exp>;
};

/** aggregated selection of "courses" */
export type Courses_Aggregate = {
  __typename?: 'courses_aggregate';
  aggregate?: Maybe<Courses_Aggregate_Fields>;
  nodes: Array<Courses>;
};

/** aggregate fields of "courses" */
export type Courses_Aggregate_Fields = {
  __typename?: 'courses_aggregate_fields';
  avg?: Maybe<Courses_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Courses_Max_Fields>;
  min?: Maybe<Courses_Min_Fields>;
  stddev?: Maybe<Courses_Stddev_Fields>;
  stddev_pop?: Maybe<Courses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Courses_Stddev_Samp_Fields>;
  sum?: Maybe<Courses_Sum_Fields>;
  var_pop?: Maybe<Courses_Var_Pop_Fields>;
  var_samp?: Maybe<Courses_Var_Samp_Fields>;
  variance?: Maybe<Courses_Variance_Fields>;
};


/** aggregate fields of "courses" */
export type Courses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Courses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Courses_Avg_Fields = {
  __typename?: 'courses_avg_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "courses". All fields are combined with a logical 'AND'. */
export type Courses_Bool_Exp = {
  _and?: InputMaybe<Array<Courses_Bool_Exp>>;
  _not?: InputMaybe<Courses_Bool_Exp>;
  _or?: InputMaybe<Array<Courses_Bool_Exp>>;
  course_purchases?: InputMaybe<Course_Purchases_Bool_Exp>;
  course_purchases_aggregate?: InputMaybe<Course_Purchases_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  lessons?: InputMaybe<Lessons_Bool_Exp>;
  lessons_aggregate?: InputMaybe<Lessons_Aggregate_Bool_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  preview?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Int_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courses" */
export enum Courses_Constraint {
  /** unique or primary key constraint on columns "id" */
  CoursesPkey = 'courses_pkey',
  /** unique or primary key constraint on columns "slug" */
  CoursesSlugKey = 'courses_slug_key'
}

/** input type for incrementing numeric columns in table "courses" */
export type Courses_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "courses" */
export type Courses_Insert_Input = {
  course_purchases?: InputMaybe<Course_Purchases_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  lessons?: InputMaybe<Lessons_Arr_Rel_Insert_Input>;
  order?: InputMaybe<Scalars['Int']>;
  preview?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Courses_Max_Fields = {
  __typename?: 'courses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Courses_Min_Fields = {
  __typename?: 'courses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "courses" */
export type Courses_Mutation_Response = {
  __typename?: 'courses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Courses>;
};

/** input type for inserting object relation for remote table "courses" */
export type Courses_Obj_Rel_Insert_Input = {
  data: Courses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Courses_On_Conflict>;
};

/** on_conflict condition type for table "courses" */
export type Courses_On_Conflict = {
  constraint: Courses_Constraint;
  update_columns?: Array<Courses_Update_Column>;
  where?: InputMaybe<Courses_Bool_Exp>;
};

/** Ordering options when selecting data from "courses". */
export type Courses_Order_By = {
  course_purchases_aggregate?: InputMaybe<Course_Purchases_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  lessons_aggregate?: InputMaybe<Lessons_Aggregate_Order_By>;
  order?: InputMaybe<Order_By>;
  preview?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: courses */
export type Courses_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "courses" */
export enum Courses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Order = 'order',
  /** column name */
  Preview = 'preview',
  /** column name */
  Price = 'price',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "courses" */
export type Courses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  preview?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Courses_Stddev_Fields = {
  __typename?: 'courses_stddev_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Courses_Stddev_Pop_Fields = {
  __typename?: 'courses_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Courses_Stddev_Samp_Fields = {
  __typename?: 'courses_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "courses" */
export type Courses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Courses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Courses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  preview?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Courses_Sum_Fields = {
  __typename?: 'courses_sum_fields';
  order?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
};

/** update columns of table "courses" */
export enum Courses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Order = 'order',
  /** column name */
  Preview = 'preview',
  /** column name */
  Price = 'price',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Courses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Courses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Courses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Courses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Courses_Var_Pop_Fields = {
  __typename?: 'courses_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Courses_Var_Samp_Fields = {
  __typename?: 'courses_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Courses_Variance_Fields = {
  __typename?: 'courses_variance_fields';
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "lessons" */
export type Lessons = {
  __typename?: 'lessons';
  /** An object relationship */
  course: Courses;
  course_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  is_deleted: Scalars['Boolean'];
  order: Scalars['Int'];
  preview: Scalars['String'];
  slug: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  video: Scalars['String'];
};

/** aggregated selection of "lessons" */
export type Lessons_Aggregate = {
  __typename?: 'lessons_aggregate';
  aggregate?: Maybe<Lessons_Aggregate_Fields>;
  nodes: Array<Lessons>;
};

export type Lessons_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Lessons_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Lessons_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Lessons_Aggregate_Bool_Exp_Count>;
};

export type Lessons_Aggregate_Bool_Exp_Bool_And = {
  arguments: Lessons_Select_Column_Lessons_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lessons_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lessons_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Lessons_Select_Column_Lessons_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lessons_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lessons_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lessons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lessons_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lessons" */
export type Lessons_Aggregate_Fields = {
  __typename?: 'lessons_aggregate_fields';
  avg?: Maybe<Lessons_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Lessons_Max_Fields>;
  min?: Maybe<Lessons_Min_Fields>;
  stddev?: Maybe<Lessons_Stddev_Fields>;
  stddev_pop?: Maybe<Lessons_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lessons_Stddev_Samp_Fields>;
  sum?: Maybe<Lessons_Sum_Fields>;
  var_pop?: Maybe<Lessons_Var_Pop_Fields>;
  var_samp?: Maybe<Lessons_Var_Samp_Fields>;
  variance?: Maybe<Lessons_Variance_Fields>;
};


/** aggregate fields of "lessons" */
export type Lessons_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lessons_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lessons" */
export type Lessons_Aggregate_Order_By = {
  avg?: InputMaybe<Lessons_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lessons_Max_Order_By>;
  min?: InputMaybe<Lessons_Min_Order_By>;
  stddev?: InputMaybe<Lessons_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lessons_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lessons_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lessons_Sum_Order_By>;
  var_pop?: InputMaybe<Lessons_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lessons_Var_Samp_Order_By>;
  variance?: InputMaybe<Lessons_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lessons" */
export type Lessons_Arr_Rel_Insert_Input = {
  data: Array<Lessons_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lessons_On_Conflict>;
};

/** aggregate avg on columns */
export type Lessons_Avg_Fields = {
  __typename?: 'lessons_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lessons" */
export type Lessons_Avg_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lessons". All fields are combined with a logical 'AND'. */
export type Lessons_Bool_Exp = {
  _and?: InputMaybe<Array<Lessons_Bool_Exp>>;
  _not?: InputMaybe<Lessons_Bool_Exp>;
  _or?: InputMaybe<Array<Lessons_Bool_Exp>>;
  course?: InputMaybe<Courses_Bool_Exp>;
  course_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  preview?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  video?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lessons" */
export enum Lessons_Constraint {
  /** unique or primary key constraint on columns "id" */
  LessonsPkey = 'lessons_pkey',
  /** unique or primary key constraint on columns "slug" */
  LessonsSlugKey = 'lessons_slug_key'
}

/** input type for incrementing numeric columns in table "lessons" */
export type Lessons_Inc_Input = {
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "lessons" */
export type Lessons_Insert_Input = {
  course?: InputMaybe<Courses_Obj_Rel_Insert_Input>;
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  preview?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lessons_Max_Fields = {
  __typename?: 'lessons_max_fields';
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lessons" */
export type Lessons_Max_Order_By = {
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  preview?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lessons_Min_Fields = {
  __typename?: 'lessons_min_fields';
  course_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  preview?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  video?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lessons" */
export type Lessons_Min_Order_By = {
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  preview?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lessons" */
export type Lessons_Mutation_Response = {
  __typename?: 'lessons_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lessons>;
};

/** on_conflict condition type for table "lessons" */
export type Lessons_On_Conflict = {
  constraint: Lessons_Constraint;
  update_columns?: Array<Lessons_Update_Column>;
  where?: InputMaybe<Lessons_Bool_Exp>;
};

/** Ordering options when selecting data from "lessons". */
export type Lessons_Order_By = {
  course?: InputMaybe<Courses_Order_By>;
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  preview?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  video?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lessons */
export type Lessons_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "lessons" */
export enum Lessons_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Order = 'order',
  /** column name */
  Preview = 'preview',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Video = 'video'
}

/** select "lessons_aggregate_bool_exp_bool_and_arguments_columns" columns of table "lessons" */
export enum Lessons_Select_Column_Lessons_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** select "lessons_aggregate_bool_exp_bool_or_arguments_columns" columns of table "lessons" */
export enum Lessons_Select_Column_Lessons_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDeleted = 'is_deleted'
}

/** input type for updating data in table "lessons" */
export type Lessons_Set_Input = {
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  preview?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Lessons_Stddev_Fields = {
  __typename?: 'lessons_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lessons" */
export type Lessons_Stddev_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lessons_Stddev_Pop_Fields = {
  __typename?: 'lessons_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lessons" */
export type Lessons_Stddev_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lessons_Stddev_Samp_Fields = {
  __typename?: 'lessons_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lessons" */
export type Lessons_Stddev_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "lessons" */
export type Lessons_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lessons_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lessons_Stream_Cursor_Value_Input = {
  course_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<Scalars['Int']>;
  preview?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  video?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Lessons_Sum_Fields = {
  __typename?: 'lessons_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lessons" */
export type Lessons_Sum_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** update columns of table "lessons" */
export enum Lessons_Update_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Order = 'order',
  /** column name */
  Preview = 'preview',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Video = 'video'
}

export type Lessons_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Lessons_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lessons_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lessons_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Lessons_Var_Pop_Fields = {
  __typename?: 'lessons_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lessons" */
export type Lessons_Var_Pop_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lessons_Var_Samp_Fields = {
  __typename?: 'lessons_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lessons" */
export type Lessons_Var_Samp_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lessons_Variance_Fields = {
  __typename?: 'lessons_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lessons" */
export type Lessons_Variance_Order_By = {
  order?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "course_purchases" */
  delete_course_purchases?: Maybe<Course_Purchases_Mutation_Response>;
  /** delete single row from the table: "course_purchases" */
  delete_course_purchases_by_pk?: Maybe<Course_Purchases>;
  /** delete data from the table: "courses" */
  delete_courses?: Maybe<Courses_Mutation_Response>;
  /** delete single row from the table: "courses" */
  delete_courses_by_pk?: Maybe<Courses>;
  /** delete data from the table: "lessons" */
  delete_lessons?: Maybe<Lessons_Mutation_Response>;
  /** delete single row from the table: "lessons" */
  delete_lessons_by_pk?: Maybe<Lessons>;
  /** delete data from the table: "payment_status" */
  delete_payment_status?: Maybe<Payment_Status_Mutation_Response>;
  /** delete single row from the table: "payment_status" */
  delete_payment_status_by_pk?: Maybe<Payment_Status>;
  /** delete data from the table: "role_types" */
  delete_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** delete single row from the table: "role_types" */
  delete_role_types_by_pk?: Maybe<Role_Types>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "course_purchases" */
  insert_course_purchases?: Maybe<Course_Purchases_Mutation_Response>;
  /** insert a single row into the table: "course_purchases" */
  insert_course_purchases_one?: Maybe<Course_Purchases>;
  /** insert data into the table: "courses" */
  insert_courses?: Maybe<Courses_Mutation_Response>;
  /** insert a single row into the table: "courses" */
  insert_courses_one?: Maybe<Courses>;
  /** insert data into the table: "lessons" */
  insert_lessons?: Maybe<Lessons_Mutation_Response>;
  /** insert a single row into the table: "lessons" */
  insert_lessons_one?: Maybe<Lessons>;
  /** insert data into the table: "payment_status" */
  insert_payment_status?: Maybe<Payment_Status_Mutation_Response>;
  /** insert a single row into the table: "payment_status" */
  insert_payment_status_one?: Maybe<Payment_Status>;
  /** insert data into the table: "role_types" */
  insert_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** insert a single row into the table: "role_types" */
  insert_role_types_one?: Maybe<Role_Types>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  register?: Maybe<RegisterUserOutput>;
  updateUser?: Maybe<UpdateUserOutput>;
  /** update data of the table: "course_purchases" */
  update_course_purchases?: Maybe<Course_Purchases_Mutation_Response>;
  /** update single row of the table: "course_purchases" */
  update_course_purchases_by_pk?: Maybe<Course_Purchases>;
  /** update multiples rows of table: "course_purchases" */
  update_course_purchases_many?: Maybe<Array<Maybe<Course_Purchases_Mutation_Response>>>;
  /** update data of the table: "courses" */
  update_courses?: Maybe<Courses_Mutation_Response>;
  /** update single row of the table: "courses" */
  update_courses_by_pk?: Maybe<Courses>;
  /** update multiples rows of table: "courses" */
  update_courses_many?: Maybe<Array<Maybe<Courses_Mutation_Response>>>;
  /** update data of the table: "lessons" */
  update_lessons?: Maybe<Lessons_Mutation_Response>;
  /** update single row of the table: "lessons" */
  update_lessons_by_pk?: Maybe<Lessons>;
  /** update multiples rows of table: "lessons" */
  update_lessons_many?: Maybe<Array<Maybe<Lessons_Mutation_Response>>>;
  /** update data of the table: "payment_status" */
  update_payment_status?: Maybe<Payment_Status_Mutation_Response>;
  /** update single row of the table: "payment_status" */
  update_payment_status_by_pk?: Maybe<Payment_Status>;
  /** update multiples rows of table: "payment_status" */
  update_payment_status_many?: Maybe<Array<Maybe<Payment_Status_Mutation_Response>>>;
  /** update data of the table: "role_types" */
  update_role_types?: Maybe<Role_Types_Mutation_Response>;
  /** update single row of the table: "role_types" */
  update_role_types_by_pk?: Maybe<Role_Types>;
  /** update multiples rows of table: "role_types" */
  update_role_types_many?: Maybe<Array<Maybe<Role_Types_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_Course_PurchasesArgs = {
  where: Course_Purchases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Course_Purchases_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CoursesArgs = {
  where: Courses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Courses_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LessonsArgs = {
  where: Lessons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Lessons_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Payment_StatusArgs = {
  where: Payment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_Status_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Role_TypesArgs = {
  where: Role_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_Types_By_PkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Course_PurchasesArgs = {
  objects: Array<Course_Purchases_Insert_Input>;
  on_conflict?: InputMaybe<Course_Purchases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Purchases_OneArgs = {
  object: Course_Purchases_Insert_Input;
  on_conflict?: InputMaybe<Course_Purchases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CoursesArgs = {
  objects: Array<Courses_Insert_Input>;
  on_conflict?: InputMaybe<Courses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Courses_OneArgs = {
  object: Courses_Insert_Input;
  on_conflict?: InputMaybe<Courses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LessonsArgs = {
  objects: Array<Lessons_Insert_Input>;
  on_conflict?: InputMaybe<Lessons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lessons_OneArgs = {
  object: Lessons_Insert_Input;
  on_conflict?: InputMaybe<Lessons_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_StatusArgs = {
  objects: Array<Payment_Status_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_Status_OneArgs = {
  object: Payment_Status_Insert_Input;
  on_conflict?: InputMaybe<Payment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_TypesArgs = {
  objects: Array<Role_Types_Insert_Input>;
  on_conflict?: InputMaybe<Role_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Types_OneArgs = {
  object: Role_Types_Insert_Input;
  on_conflict?: InputMaybe<Role_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRegisterArgs = {
  input: RegisterUserInput;
};


/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  input: UpdateUserInput;
};


/** mutation root */
export type Mutation_RootUpdate_Course_PurchasesArgs = {
  _inc?: InputMaybe<Course_Purchases_Inc_Input>;
  _set?: InputMaybe<Course_Purchases_Set_Input>;
  where: Course_Purchases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Purchases_By_PkArgs = {
  _inc?: InputMaybe<Course_Purchases_Inc_Input>;
  _set?: InputMaybe<Course_Purchases_Set_Input>;
  pk_columns: Course_Purchases_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Purchases_ManyArgs = {
  updates: Array<Course_Purchases_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CoursesArgs = {
  _inc?: InputMaybe<Courses_Inc_Input>;
  _set?: InputMaybe<Courses_Set_Input>;
  where: Courses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Courses_By_PkArgs = {
  _inc?: InputMaybe<Courses_Inc_Input>;
  _set?: InputMaybe<Courses_Set_Input>;
  pk_columns: Courses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Courses_ManyArgs = {
  updates: Array<Courses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LessonsArgs = {
  _inc?: InputMaybe<Lessons_Inc_Input>;
  _set?: InputMaybe<Lessons_Set_Input>;
  where: Lessons_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Lessons_By_PkArgs = {
  _inc?: InputMaybe<Lessons_Inc_Input>;
  _set?: InputMaybe<Lessons_Set_Input>;
  pk_columns: Lessons_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Lessons_ManyArgs = {
  updates: Array<Lessons_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_StatusArgs = {
  _set?: InputMaybe<Payment_Status_Set_Input>;
  where: Payment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Status_By_PkArgs = {
  _set?: InputMaybe<Payment_Status_Set_Input>;
  pk_columns: Payment_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_Status_ManyArgs = {
  updates: Array<Payment_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Role_TypesArgs = {
  _set?: InputMaybe<Role_Types_Set_Input>;
  where: Role_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Types_By_PkArgs = {
  _set?: InputMaybe<Role_Types_Set_Input>;
  pk_columns: Role_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Types_ManyArgs = {
  updates: Array<Role_Types_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payment_status" */
export type Payment_Status = {
  __typename?: 'payment_status';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "payment_status" */
export type Payment_Status_Aggregate = {
  __typename?: 'payment_status_aggregate';
  aggregate?: Maybe<Payment_Status_Aggregate_Fields>;
  nodes: Array<Payment_Status>;
};

/** aggregate fields of "payment_status" */
export type Payment_Status_Aggregate_Fields = {
  __typename?: 'payment_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Payment_Status_Max_Fields>;
  min?: Maybe<Payment_Status_Min_Fields>;
};


/** aggregate fields of "payment_status" */
export type Payment_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "payment_status". All fields are combined with a logical 'AND'. */
export type Payment_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Status_Bool_Exp>>;
  _not?: InputMaybe<Payment_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "payment_status" */
export enum Payment_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  PaymentStatusEnumPkey = 'payment_status_enum_pkey'
}

export enum Payment_Status_Enum {
  /** Оплату відмінено */
  Failed = 'FAILED',
  /** Очікуємо оплати */
  Processing = 'PROCESSING',
  /** Оплачено */
  Succeeded = 'SUCCEEDED'
}

/** Boolean expression to compare columns of type "payment_status_enum". All fields are combined with logical 'AND'. */
export type Payment_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Payment_Status_Enum>;
  _in?: InputMaybe<Array<Payment_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Payment_Status_Enum>;
  _nin?: InputMaybe<Array<Payment_Status_Enum>>;
};

/** input type for inserting data into table "payment_status" */
export type Payment_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Payment_Status_Max_Fields = {
  __typename?: 'payment_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Payment_Status_Min_Fields = {
  __typename?: 'payment_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "payment_status" */
export type Payment_Status_Mutation_Response = {
  __typename?: 'payment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Status>;
};

/** input type for inserting object relation for remote table "payment_status" */
export type Payment_Status_Obj_Rel_Insert_Input = {
  data: Payment_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Status_On_Conflict>;
};

/** on_conflict condition type for table "payment_status" */
export type Payment_Status_On_Conflict = {
  constraint: Payment_Status_Constraint;
  update_columns?: Array<Payment_Status_Update_Column>;
  where?: InputMaybe<Payment_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_status". */
export type Payment_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: payment_status */
export type Payment_Status_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "payment_status" */
export enum Payment_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "payment_status" */
export type Payment_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "payment_status" */
export type Payment_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "payment_status" */
export enum Payment_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

export type Payment_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Status_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  cloudinarySignature?: Maybe<CloudinarySignatureOutput>;
  /** An array relationship */
  course_purchases: Array<Course_Purchases>;
  /** An aggregate relationship */
  course_purchases_aggregate: Course_Purchases_Aggregate;
  /** fetch data from the table: "course_purchases" using primary key columns */
  course_purchases_by_pk?: Maybe<Course_Purchases>;
  /** fetch data from the table: "courses" */
  courses: Array<Courses>;
  /** fetch aggregated fields from the table: "courses" */
  courses_aggregate: Courses_Aggregate;
  /** fetch data from the table: "courses" using primary key columns */
  courses_by_pk?: Maybe<Courses>;
  getUser?: Maybe<GetUserOutput>;
  /** An array relationship */
  lessons: Array<Lessons>;
  /** An aggregate relationship */
  lessons_aggregate: Lessons_Aggregate;
  /** fetch data from the table: "lessons" using primary key columns */
  lessons_by_pk?: Maybe<Lessons>;
  login?: Maybe<LoginOutput>;
  /** fetch data from the table: "payment_status" */
  payment_status: Array<Payment_Status>;
  /** fetch aggregated fields from the table: "payment_status" */
  payment_status_aggregate: Payment_Status_Aggregate;
  /** fetch data from the table: "payment_status" using primary key columns */
  payment_status_by_pk?: Maybe<Payment_Status>;
  /** fetch data from the table: "role_types" */
  role_types: Array<Role_Types>;
  /** fetch aggregated fields from the table: "role_types" */
  role_types_aggregate: Role_Types_Aggregate;
  /** fetch data from the table: "role_types" using primary key columns */
  role_types_by_pk?: Maybe<Role_Types>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootCourse_PurchasesArgs = {
  distinct_on?: InputMaybe<Array<Course_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Purchases_Order_By>>;
  where?: InputMaybe<Course_Purchases_Bool_Exp>;
};


export type Query_RootCourse_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Purchases_Order_By>>;
  where?: InputMaybe<Course_Purchases_Bool_Exp>;
};


export type Query_RootCourse_Purchases_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCoursesArgs = {
  distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Courses_Order_By>>;
  where?: InputMaybe<Courses_Bool_Exp>;
};


export type Query_RootCourses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Courses_Order_By>>;
  where?: InputMaybe<Courses_Bool_Exp>;
};


export type Query_RootCourses_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLessonsArgs = {
  distinct_on?: InputMaybe<Array<Lessons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lessons_Order_By>>;
  where?: InputMaybe<Lessons_Bool_Exp>;
};


export type Query_RootLessons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lessons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lessons_Order_By>>;
  where?: InputMaybe<Lessons_Bool_Exp>;
};


export type Query_RootLessons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLoginArgs = {
  input: LoginInput;
};


export type Query_RootPayment_StatusArgs = {
  distinct_on?: InputMaybe<Array<Payment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Status_Order_By>>;
  where?: InputMaybe<Payment_Status_Bool_Exp>;
};


export type Query_RootPayment_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Status_Order_By>>;
  where?: InputMaybe<Payment_Status_Bool_Exp>;
};


export type Query_RootPayment_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootRole_TypesArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Query_RootRole_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Query_RootRole_Types_By_PkArgs = {
  role: Scalars['String'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "role_types" */
export type Role_Types = {
  __typename?: 'role_types';
  role: Scalars['String'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
};


/** columns and relationships of "role_types" */
export type Role_TypesUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "role_types" */
export type Role_TypesUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "role_types" */
export type Role_Types_Aggregate = {
  __typename?: 'role_types_aggregate';
  aggregate?: Maybe<Role_Types_Aggregate_Fields>;
  nodes: Array<Role_Types>;
};

/** aggregate fields of "role_types" */
export type Role_Types_Aggregate_Fields = {
  __typename?: 'role_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Role_Types_Max_Fields>;
  min?: Maybe<Role_Types_Min_Fields>;
};


/** aggregate fields of "role_types" */
export type Role_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "role_types". All fields are combined with a logical 'AND'. */
export type Role_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Types_Bool_Exp>>;
  _not?: InputMaybe<Role_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Types_Bool_Exp>>;
  role?: InputMaybe<String_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "role_types" */
export enum Role_Types_Constraint {
  /** unique or primary key constraint on columns "role" */
  RoleTypesPkey = 'role_types_pkey',
  /** unique or primary key constraint on columns "role" */
  RoleTypesRoleKey = 'role_types_role_key'
}

export enum Role_Types_Enum {
  Admin = 'admin',
  User = 'user'
}

/** Boolean expression to compare columns of type "role_types_enum". All fields are combined with logical 'AND'. */
export type Role_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Role_Types_Enum>;
  _in?: InputMaybe<Array<Role_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Role_Types_Enum>;
  _nin?: InputMaybe<Array<Role_Types_Enum>>;
};

/** input type for inserting data into table "role_types" */
export type Role_Types_Insert_Input = {
  role?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Role_Types_Max_Fields = {
  __typename?: 'role_types_max_fields';
  role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Role_Types_Min_Fields = {
  __typename?: 'role_types_min_fields';
  role?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "role_types" */
export type Role_Types_Mutation_Response = {
  __typename?: 'role_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Types>;
};

/** input type for inserting object relation for remote table "role_types" */
export type Role_Types_Obj_Rel_Insert_Input = {
  data: Role_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Types_On_Conflict>;
};

/** on_conflict condition type for table "role_types" */
export type Role_Types_On_Conflict = {
  constraint: Role_Types_Constraint;
  update_columns?: Array<Role_Types_Update_Column>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "role_types". */
export type Role_Types_Order_By = {
  role?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: role_types */
export type Role_Types_Pk_Columns_Input = {
  role: Scalars['String'];
};

/** select columns of table "role_types" */
export enum Role_Types_Select_Column {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "role_types" */
export type Role_Types_Set_Input = {
  role?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "role_types" */
export type Role_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Types_Stream_Cursor_Value_Input = {
  role?: InputMaybe<Scalars['String']>;
};

/** update columns of table "role_types" */
export enum Role_Types_Update_Column {
  /** column name */
  Role = 'role'
}

export type Role_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Types_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  course_purchases: Array<Course_Purchases>;
  /** An aggregate relationship */
  course_purchases_aggregate: Course_Purchases_Aggregate;
  /** fetch data from the table: "course_purchases" using primary key columns */
  course_purchases_by_pk?: Maybe<Course_Purchases>;
  /** fetch data from the table in a streaming manner: "course_purchases" */
  course_purchases_stream: Array<Course_Purchases>;
  /** fetch data from the table: "courses" */
  courses: Array<Courses>;
  /** fetch aggregated fields from the table: "courses" */
  courses_aggregate: Courses_Aggregate;
  /** fetch data from the table: "courses" using primary key columns */
  courses_by_pk?: Maybe<Courses>;
  /** fetch data from the table in a streaming manner: "courses" */
  courses_stream: Array<Courses>;
  /** An array relationship */
  lessons: Array<Lessons>;
  /** An aggregate relationship */
  lessons_aggregate: Lessons_Aggregate;
  /** fetch data from the table: "lessons" using primary key columns */
  lessons_by_pk?: Maybe<Lessons>;
  /** fetch data from the table in a streaming manner: "lessons" */
  lessons_stream: Array<Lessons>;
  /** fetch data from the table: "payment_status" */
  payment_status: Array<Payment_Status>;
  /** fetch aggregated fields from the table: "payment_status" */
  payment_status_aggregate: Payment_Status_Aggregate;
  /** fetch data from the table: "payment_status" using primary key columns */
  payment_status_by_pk?: Maybe<Payment_Status>;
  /** fetch data from the table in a streaming manner: "payment_status" */
  payment_status_stream: Array<Payment_Status>;
  /** fetch data from the table: "role_types" */
  role_types: Array<Role_Types>;
  /** fetch aggregated fields from the table: "role_types" */
  role_types_aggregate: Role_Types_Aggregate;
  /** fetch data from the table: "role_types" using primary key columns */
  role_types_by_pk?: Maybe<Role_Types>;
  /** fetch data from the table in a streaming manner: "role_types" */
  role_types_stream: Array<Role_Types>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
};


export type Subscription_RootCourse_PurchasesArgs = {
  distinct_on?: InputMaybe<Array<Course_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Purchases_Order_By>>;
  where?: InputMaybe<Course_Purchases_Bool_Exp>;
};


export type Subscription_RootCourse_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Purchases_Order_By>>;
  where?: InputMaybe<Course_Purchases_Bool_Exp>;
};


export type Subscription_RootCourse_Purchases_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCourse_Purchases_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Course_Purchases_Stream_Cursor_Input>>;
  where?: InputMaybe<Course_Purchases_Bool_Exp>;
};


export type Subscription_RootCoursesArgs = {
  distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Courses_Order_By>>;
  where?: InputMaybe<Courses_Bool_Exp>;
};


export type Subscription_RootCourses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Courses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Courses_Order_By>>;
  where?: InputMaybe<Courses_Bool_Exp>;
};


export type Subscription_RootCourses_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCourses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Courses_Stream_Cursor_Input>>;
  where?: InputMaybe<Courses_Bool_Exp>;
};


export type Subscription_RootLessonsArgs = {
  distinct_on?: InputMaybe<Array<Lessons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lessons_Order_By>>;
  where?: InputMaybe<Lessons_Bool_Exp>;
};


export type Subscription_RootLessons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lessons_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lessons_Order_By>>;
  where?: InputMaybe<Lessons_Bool_Exp>;
};


export type Subscription_RootLessons_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLessons_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lessons_Stream_Cursor_Input>>;
  where?: InputMaybe<Lessons_Bool_Exp>;
};


export type Subscription_RootPayment_StatusArgs = {
  distinct_on?: InputMaybe<Array<Payment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Status_Order_By>>;
  where?: InputMaybe<Payment_Status_Bool_Exp>;
};


export type Subscription_RootPayment_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Status_Order_By>>;
  where?: InputMaybe<Payment_Status_Bool_Exp>;
};


export type Subscription_RootPayment_Status_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPayment_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Status_Bool_Exp>;
};


export type Subscription_RootRole_TypesArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootRole_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Role_Types_Order_By>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootRole_Types_By_PkArgs = {
  role: Scalars['String'];
};


export type Subscription_RootRole_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Role_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Types_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  confirmation_token?: Maybe<Scalars['String']>;
  /** An array relationship */
  course_purchases: Array<Course_Purchases>;
  /** An aggregate relationship */
  course_purchases_aggregate: Course_Purchases_Aggregate;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  id: Scalars['uuid'];
  is_confirmed: Scalars['Boolean'];
  is_deleted: Scalars['Boolean'];
  password: Scalars['String'];
  role: Role_Types_Enum;
  /** An object relationship */
  role_type: Role_Types;
  updated_at: Scalars['timestamptz'];
  username: Scalars['String'];
};


/** columns and relationships of "users" */
export type UsersCourse_PurchasesArgs = {
  distinct_on?: InputMaybe<Array<Course_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Purchases_Order_By>>;
  where?: InputMaybe<Course_Purchases_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCourse_Purchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Purchases_Order_By>>;
  where?: InputMaybe<Course_Purchases_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  confirmation_token?: InputMaybe<String_Comparison_Exp>;
  course_purchases?: InputMaybe<Course_Purchases_Bool_Exp>;
  course_purchases_aggregate?: InputMaybe<Course_Purchases_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_confirmed?: InputMaybe<Boolean_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Role_Types_Enum_Comparison_Exp>;
  role_type?: InputMaybe<Role_Types_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameKey = 'users_username_key'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  confirmation_token?: InputMaybe<Scalars['String']>;
  course_purchases?: InputMaybe<Course_Purchases_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_confirmed?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role_Types_Enum>;
  role_type?: InputMaybe<Role_Types_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  confirmation_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  confirmation_token?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  confirmation_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  confirmation_token?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  confirmation_token?: InputMaybe<Order_By>;
  course_purchases_aggregate?: InputMaybe<Course_Purchases_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_confirmed?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  role_type?: InputMaybe<Role_Types_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsConfirmed = 'is_confirmed',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsConfirmed = 'is_confirmed',
  /** column name */
  IsDeleted = 'is_deleted'
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsConfirmed = 'is_confirmed',
  /** column name */
  IsDeleted = 'is_deleted'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  confirmation_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_confirmed?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  confirmation_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_confirmed?: InputMaybe<Scalars['Boolean']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  ConfirmationToken = 'confirmation_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsConfirmed = 'is_confirmed',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Password = 'password',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type CloudinarySignatureQueryVariables = Exact<{ [key: string]: never; }>;


export type CloudinarySignatureQuery = { __typename?: 'query_root', cloudinarySignature?: { __typename?: 'CloudinarySignatureOutput', apiKey: string, cloudName: string, publicId: string, signature: string, timestamp: number } | null };

export type RegisterMutationVariables = Exact<{
  username: Scalars['String'];
  role: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
}>;


export type RegisterMutation = { __typename?: 'mutation_root', register?: { __typename?: 'RegisterUserOutput', id: any, username: string, email: string, role: string, password: string } | null };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'query_root', getUser?: { __typename?: 'GetUserOutput', id: string, username: string, email: string, role: string } | null };

export type LoginQueryVariables = Exact<{
  password: Scalars['String'];
  username: Scalars['String'];
}>;


export type LoginQuery = { __typename?: 'query_root', login?: { __typename?: 'LoginOutput', accessToken: string } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  username: Scalars['String'];
  password: Scalars['String'];
  email: Scalars['String'];
  is_deleted: Scalars['Boolean'];
  role: Scalars['String'];
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', updateUser?: { __typename?: 'UpdateUserOutput', id: any, username: string, email: string, role: string, password: string, is_deleted: boolean } | null };


export const CloudinarySignatureDocument = gql`
    query CloudinarySignature {
  cloudinarySignature {
    apiKey
    cloudName
    publicId
    signature
    timestamp
  }
}
    `;

/**
 * __useCloudinarySignatureQuery__
 *
 * To run a query within a React component, call `useCloudinarySignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudinarySignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudinarySignatureQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloudinarySignatureQuery(baseOptions?: Apollo.QueryHookOptions<CloudinarySignatureQuery, CloudinarySignatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CloudinarySignatureQuery, CloudinarySignatureQueryVariables>(CloudinarySignatureDocument, options);
      }
export function useCloudinarySignatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CloudinarySignatureQuery, CloudinarySignatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CloudinarySignatureQuery, CloudinarySignatureQueryVariables>(CloudinarySignatureDocument, options);
        }
export type CloudinarySignatureQueryHookResult = ReturnType<typeof useCloudinarySignatureQuery>;
export type CloudinarySignatureLazyQueryHookResult = ReturnType<typeof useCloudinarySignatureLazyQuery>;
export type CloudinarySignatureQueryResult = Apollo.QueryResult<CloudinarySignatureQuery, CloudinarySignatureQueryVariables>;
export const RegisterDocument = gql`
    mutation Register($username: String!, $role: String!, $password: String!, $email: String!) {
  register(
    input: {email: $email, password: $password, role: $role, username: $username}
  ) {
    id
    username
    email
    role
    password
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      username: // value for 'username'
 *      role: // value for 'role'
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const GetUserDocument = gql`
    query GetUser {
  getUser {
    id
    username
    email
    role
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const LoginDocument = gql`
    query Login($password: String!, $username: String!) {
  login(input: {password: $password, username: $username}) {
    accessToken
  }
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      password: // value for 'password'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useLoginQuery(baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: uuid!, $username: String!, $password: String!, $email: String!, $is_deleted: Boolean!, $role: String!) {
  updateUser(
    input: {id: $id, username: $username, password: $password, email: $email, is_deleted: $is_deleted, role: $role}
  ) {
    id
    username
    email
    role
    password
    is_deleted
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      email: // value for 'email'
 *      is_deleted: // value for 'is_deleted'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;