import { apolloClient } from "@app/core/apollo-client";
import {
  GetUserQuery,
  LoginQuery,
  LoginQueryVariables,
  Role_Types_Enum,
} from "@app/core/types";
import LoginQueryGql from "@app/core/graphql/login.gql";
import GetUserQueryGql from "@app/core/graphql/get-user.gql";
import { JWT_USER_TOKEN } from "@app/core/constants";
import OneSignalReact from "react-onesignal";

export const authProvider = {
  login: async (variables: LoginQueryVariables) => {
    const { data } = await apolloClient.query<LoginQuery, LoginQueryVariables>({
      query: LoginQueryGql,
      variables,
      fetchPolicy: "no-cache",
    });

    if (!data.login?.accessToken) {
      return Promise.reject();
    }

    localStorage.setItem(JWT_USER_TOKEN, data.login.accessToken);
  },
  logout: () => {
    OneSignalReact.removeExternalUserId();
    localStorage.removeItem(JWT_USER_TOKEN);
    return Promise.resolve();
  },
  checkAuth: () => {
    if (!localStorage.getItem(JWT_USER_TOKEN)) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkError: (error: any) => {
    const status = error.status;

    if (status === 401 || status === 403) {
      localStorage.removeItem(JWT_USER_TOKEN);
      return Promise.reject({ redirectTo: "/login", logoutUser: true });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: async () => {
    const { data } = await apolloClient.query<GetUserQuery>({
      query: GetUserQueryGql,
      fetchPolicy: "no-cache",
    });

    OneSignalReact.setExternalUserId(data.getUser!.id);

    return Promise.resolve({
      id: data.getUser!.id,
      fullName: data.getUser!.username,
      role: data.getUser!.role,
    });
  },
  getPermissions: async () => {
    try {
      const { data } = await apolloClient.query<GetUserQuery>({
        query: GetUserQueryGql,
        fetchPolicy: "no-cache",
      });

      const role = data.getUser!.role;

      if (!role) {
        return Promise.reject({ status: 401 });
      }

      if (role === Role_Types_Enum.Admin) {
        return Promise.resolve([
          { action: ["read", "create", "edit", "export"], resource: "courses" },
          {
            action: ["read", "create", "edit", "export"],
            resource: "course_purchases",
          },
          {
            action: ["read", "create", "edit", "export"],
            resource: "lessons",
          },
          { action: ["read", "create", "edit", "export"], resource: "users" },
        ]);
      }

      if (role === Role_Types_Enum.User) {
        return Promise.reject({ status: 401 });
      }

      return Promise.reject({ status: 403 });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
