import { Card, CardContent, CardHeader, Skeleton } from "@mui/material";
import { FC, useRef } from "react";
import { Button } from "react-admin";

interface VideoInputUIProps {
  label: string;
  disabled: boolean;
  onImageSelected: (image: File) => void;
  value?: string;
  fitImage?: boolean;
}

export const VideoInputUI: FC<VideoInputUIProps> = ({
  label,
  disabled,
  onImageSelected,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) {
      return;
    }

    const [file] = Array.from(e.target.files);

    if (!file) {
      return;
    }

    onImageSelected(file);
  };

  const onUploadClick = () => {
    inputRef.current?.click();
  };

  return (
    <div style={{ marginBottom: 20, width: "100%" }}>
      <input
        type="file"
        accept="video/mp4, video/ogv, video/webm"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={onFileInputChange}
      />
      <Card variant="outlined">
        <CardHeader title={label} />
        <CardContent>
          {value && !disabled ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <video
                controls
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                  maxWidth: 600,
                }}
              >
                <source src={value} />
              </video>
              <div>
                <Button
                  onClick={onUploadClick}
                  label="Завантажити нове відео"
                  size="medium"
                />
              </div>
            </div>
          ) : (
            <Skeleton
              variant="rectangular"
              width={384}
              height={240}
              style={{ cursor: "pointer" }}
              onClick={() => !disabled && onUploadClick()}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
};
