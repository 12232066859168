import {
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { CloudinaryInput } from "@app/common/components/cloudinary-input/cloudinary-input.component";
import { VideoInput } from "@app/common/components/video-upload-input/video-input.component";
import { convertToSlug } from "@app/core/utils";

export const LessonsEdit = () => (
  <Edit redirect="list" transform={(data: any) => ({ ...data, slug: convertToSlug(data.slug) })}>
    <SimpleForm>
      <TextInput source="title" fullWidth label="Назва" validate={required()} />
      <TextInput source="slug" fullWidth label="Слаг" validate={required()} />
      <ReferenceInput source="course_id" reference="courses">
        <SelectInput
          optionValue="id"
          optionText="title"
          label="Курс"
          fullWidth
          validate={required()}
        />
      </ReferenceInput>
      <VideoInput source="video" label="Відео" validate={required()} />
      <RichTextInput
        source="description"
        fullWidth
        label="Опис"
        validate={required()}
      />
      <NumberInput
        source="order"
        label="Порядок"
        defaultValue={0}
        validate={required()}
      />
      <BooleanInput source="is_deleted" label="Деактивувати" />
      <CloudinaryInput source="preview" label="Прев'ю" validate={required()} />
    </SimpleForm>
  </Edit>
);
