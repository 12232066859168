import { Payment_Status_Enum } from "@app/core/types";
import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const CoursePurchasesCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <ReferenceInput source="user_id" reference="users">
        <SelectInput
          optionValue="id"
          optionText="email"
          label="Користувач"
          fullWidth
          validate={required()}
        />
      </ReferenceInput>

      <ReferenceInput source="course_id" reference="courses">
        <SelectInput
          optionValue="id"
          optionText="title"
          label="Курс"
          fullWidth
          validate={required()}
        />
      </ReferenceInput>

      <TextInput
        source="payment_status"
        label="Статус Оплати"
        validate={required()}
        disabled
        defaultValue={Payment_Status_Enum.Succeeded}
      ></TextInput>
    </SimpleForm>
  </Create>
);
