import { gql, useMutation } from "@apollo/client";
import { apolloClient } from "@app/core/apollo-client";
import buildHasuraProvider from "ra-data-hasura";
import { CreateParams, DataProvider, DataProviderResult } from "react-admin";
import { RegisterMutation, UpdateUserMutation } from "./types";
import CreateUserMutationGql from "@app/core/graphql/create-user.gql";
import UpdateUserMutationGql from "@app/core/graphql/update-user.gql";

export const buildDataProvider = async () => {
  const dataProviderHasura = await buildHasuraProvider({
    client: apolloClient,
  });

  const customCreateUser = async (params: CreateParams<any>) => {
    const { data } = params;
    try {
      const response = await apolloClient.mutate<RegisterMutation>({
        mutation: CreateUserMutationGql,
        variables: data,
        fetchPolicy: "no-cache",
      });

      return { data: response?.data?.register };
    } catch (error) {
      console.error(error);
      throw new Error("Error creating user");
    }
  };

  const customUpdateUser = async (params: CreateParams<any>) => {
    const { data } = params;
    try {
      const response = await apolloClient.mutate<UpdateUserMutation>({
        mutation: UpdateUserMutationGql,
        variables: data,
        fetchPolicy: "no-cache",
      });

      return { data: response?.data?.updateUser };
    } catch (error) {
      console.error(error);
      throw new Error("Error creating user");
    }
  };

  const dp: DataProvider = {
    getList: (resource, params) => dataProviderHasura.getList(resource, params),
    getOne: (resource, params) => dataProviderHasura.getOne(resource, params),
    getMany: (resource, params) => dataProviderHasura.getMany(resource, params),
    getManyReference: (resource, params) =>
      dataProviderHasura.getManyReference(resource, params),
    update: (resource, params) => {
      delete params.previousData.client_address;

      if (resource === "users") {
        return customUpdateUser(params) as any;
      }


      return dataProviderHasura.update(resource, params);
    },
    updateMany: (resource, params) =>
      dataProviderHasura.updateMany(resource, params),
    create: (resource, params) => {
      if (resource === "users") {
        return customCreateUser(params) as any;
      }

      return dataProviderHasura.create(resource, params);
    },
    delete: (resource, params) => dataProviderHasura.delete(resource, params),
    deleteMany: (resource, params) =>
      dataProviderHasura.deleteMany(resource, params),
  };

  return dp;
};
