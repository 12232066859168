import {
  BooleanField,
  Datagrid,
  ImageField,
  List,
  NumberField,
  RichTextField,
  TextField,
} from "react-admin";

export const CoursesList = () => (
  <List sort={{ field: "order", order: "ASC" }}>
    <Datagrid rowClick="edit">
      <ImageField
        source="preview"
        label="Прев'ю"
        sx={{
          "& img": {
            maxWidth: 50,
            maxHeight: 50,
            margin: "-1px!important",
          },
          "&": {
            background: "#fff",
            width: "50px",
            height: "50px",
            border: "1px solid #fbbf24",
          },
        }}
      />
      <TextField source="title" label="Назва" />
      <TextField source="slug" label="Слаг" />
      <RichTextField source="description" label="Повний Опис" />
      <RichTextField source="short_description" label="Короткий Опис" />
      <BooleanField source="is_offline" label="Офлайн курс" />
      <BooleanField source="is_deleted" label="Деактивований" />
      <NumberField source="price" label="Ціна" />
      <NumberField source="order" label="Порядок" defaultValue={0} />
    </Datagrid>
  </List>
);
