import React from "react";
import {
  BooleanInput,
  Create,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { CloudinaryInput } from "@app/common/components/cloudinary-input/cloudinary-input.component";
import { convertToSlug } from "@app/core/utils";

export const CoursesCreate = () => (
  <Create
    redirect="list"
    transform={(data: any) => ({ ...data, slug: convertToSlug(data.slug) })}
  >
    <SimpleForm>
      <TextInput source="title" fullWidth label="Назва" validate={required()} />
      <TextInput source="slug" fullWidth label="Слаг" validate={required()} />
      <RichTextInput
        source="description"
        fullWidth
        label="Опис"
        validate={required()}
      />
      <RichTextInput
        source="short_description"
        fullWidth
        label="Короткий Опис"
        validate={required()}
      />
      <NumberInput
        source="price"
        label="Ціна"
        validate={required()}
      />
      <BooleanInput
        source="is_offline"
        label="Офлайн курс"
        validate={required()}
      />
      <NumberInput
        source="order"
        label="Порядок"
        defaultValue={0}
        validate={required()}
      />
      <CloudinaryInput source="preview" label="Прев'ю" validate={required()} />
    </SimpleForm>
  </Create>
);
