import {
  BooleanInput,
  Edit,
  PasswordInput,
  SelectInput,
  SimpleForm,
  TextInput,
  minLength,
  required,
} from "react-admin";

const validatePassword = [
  required(),
  minLength(6), // Password must be at least 8 characters long
];

export const UsersEdit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <TextInput
        source="username"
        disabled
        fullWidth
        label="Логін"
        validate={required()}
      />
      <TextInput source="email" fullWidth label="Імейл" validate={required()} />
      <PasswordInput
        source="password"
        fullWidth
        label="Пароль"
        validate={validatePassword}
      />
      <SelectInput
        disabled
        source="role"
        label="Роль"
        choices={[
          { id: "admin", role: "Адмін" },
          { id: "user", role: "Користувач" },
        ]}
        optionText="role"
        defaultValue="user"
        fullWidth
        validate={required()}
      />
      <BooleanInput source="is_deleted" label="Деактивувати" fullWidth />
    </SimpleForm>
  </Edit>
);
