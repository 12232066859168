import { FC, useState } from "react";
import { InputProps, useInput, useNotify } from "react-admin";
import axios, { AxiosError } from "axios";
import { VideoInputUI } from "../video-input-ui/video-input-ui.component";
import { JWT_USER_TOKEN } from "@app/core/constants";
import { config } from "@app/core/config";

export const VideoInput: FC<InputProps> = (props) => {
  const { label, source } = props;
  const computedLabel = String(label) ?? source;
  const [isVideoImage, setisVideoImage] = useState(false);

  const notify = useNotify();

  const {
    field: { onChange, value },
  } = useInput(props);

  const onVideoSelected = async (image: File) => {
    const url = `${config.frontendEndpoint}/api/video-upload`;
    const formData = new FormData();

    formData.append("file", image);

    try {
      setisVideoImage(true);
      const response = await axios.post(url, formData, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem(JWT_USER_TOKEN)}`,
        },
      });

      const data = response.data;

      if (!data.success) {
        setisVideoImage(false);
        return notify(data.message);
      }

      if (data.success) {
        onChange(data.url);
      }

      setisVideoImage(false);
    } catch (error) {
      setisVideoImage(false);
      return notify((error as AxiosError).message);
    }
  };

  return (
    <VideoInputUI
      label={computedLabel}
      value={value}
      disabled={isVideoImage}
      onImageSelected={onVideoSelected}
    />
  );
};
