import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from "react-admin";

export const CoursePurchasesList = () => (
  <List sort={{ field: "id", order: "ASC" }}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="Id" />

      <ReferenceField source="user_id" reference="users" label="Користувач">
        <TextField source="email" label="Користувач" />
      </ReferenceField>

      <ReferenceField source="course_id" reference="courses" label="Курс">
        <TextField source="title" label="Курс" />
      </ReferenceField>

      <DateField source="purchase_date" label="Дата створення" />
      <TextField source="payment_status" label="Статус оплати" />
    </Datagrid>
  </List>
);
