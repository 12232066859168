import { BooleanField, Datagrid, List, TextField } from "react-admin";

export const UsersList = () => {
  return (
    <List sort={{ field: "username", order: "ASC" }}>
      <Datagrid rowClick="edit">
        <TextField source="username" label="Логін" />
        <TextField source="email" label="Імейл" />
        <BooleanField source="is_deleted" label="Деактивований" />
      </Datagrid>
    </List>
  );
};
