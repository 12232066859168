import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
} from "react-admin";

export const CoursePurchasesEdit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <ReferenceInput source="user_id" reference="users">
        <SelectInput
          optionValue="id"
          optionText="email"
          label="Користувач"
          fullWidth
          validate={required()}
        />
      </ReferenceInput>

      <ReferenceInput source="course_id" reference="courses">
        <SelectInput
          optionValue="id"
          optionText="title"
          label="Курс"
          fullWidth
          validate={required()}
        />
      </ReferenceInput>

      
        <SelectInput
         source="payment_status"
         label="Статус оплати"
         fullWidth
         validate={required()}
         choices={[
           { id: 'PROCESSING', name: 'PROCESSING' },
           { id: 'FAILED', name: 'FAILED' },
           { id: 'SUCCEEDED', name: 'SUCCEEDED' },
         ]}
         optionValue="id"
         optionText="name"
        />
    </SimpleForm>
  </Edit>
);
