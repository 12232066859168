import {
  Create,
  PasswordInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  minLength,
  required,
} from "react-admin";

const validatePassword = [
  required(),
  minLength(6), // Password must be at least 8 characters long
];

export const UsersCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput
        source="username"
        fullWidth
        label="Логін"
        validate={required()}
      />
      <TextInput source="email" fullWidth label="Імейл" validate={required()} />
      <PasswordInput
        source="password"
        fullWidth
        label="Пароль"
        validate={validatePassword}
      />
      <SelectInput
        source="role"
        label="Роль"
        choices={[
          { id: "admin", role: "Адмін" },
          { id: "user", role: "Користувач" },
        ]}
        optionText="role"
        defaultValue="user"
        fullWidth
        validate={required()}
      />
    </SimpleForm>
  </Create>
);
