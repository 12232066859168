export function convertToSlug(text: string) {
  let slug = "";

  const cyrillicToLatinMap: { [key: string]: string } = {
    А: "A",
    а: "a",
    Б: "B",
    б: "b",
    В: "V",
    в: "v",
    Г: "G",
    г: "g",
    Ґ: "G",
    ґ: "g",
    Д: "D",
    д: "d",
    Е: "E",
    е: "e",
    Є: "Ye",
    є: "ye",
    Ж: "Zh",
    ж: "zh",
    З: "Z",
    з: "z",
    И: "Y",
    и: "y",
    І: "I",
    і: "i",
    Ї: "Yi",
    ї: "yi",
    Й: "Y",
    й: "y",
    К: "K",
    к: "k",
    Л: "L",
    л: "l",
    М: "M",
    м: "m",
    Н: "N",
    н: "n",
    О: "O",
    о: "o",
    П: "P",
    п: "p",
    Р: "R",
    р: "r",
    С: "S",
    с: "s",
    Т: "T",
    т: "t",
    У: "U",
    у: "u",
    Ф: "F",
    ф: "f",
    Х: "Kh",
    х: "kh",
    Ц: "Ts",
    ц: "ts",
    Ч: "Ch",
    ч: "ch",
    Ш: "Sh",
    ш: "sh",
    Щ: "Shch",
    щ: "shch",
    Ю: "Yu",
    ю: "yu",
    Я: "Ya",
    я: "ya",
    Ь: "",
    ь: "",
    Ъ: "",
    ъ: "",
    Ы: "Y",
    ы: "y",
    Э: "E",
    э: "e",
    Ё: "Yo",
    ё: "yo",
  };

  for (let i = 0; i < text.length; i++) {
    if (cyrillicToLatinMap[text[i]]) {
      slug += cyrillicToLatinMap[text[i]];
    } else {
      slug += text[i];
    }
  }

  return slug
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}
